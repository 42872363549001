* {
    margin: 0;
    box-sizing: border-box;
}

:root {
    --primary: #7B9B50;
    --primaryDark: var(--primaryDark);
    --primaryLight: #285f19;

    --secondary: #707070;
    --secondaryDark: #1D1D1D;
    --secondaryLight: #555555;

    --tertiary: #F6F6F6;


    --mainFont: 'Barlow', sans-serif;

    /* Nav + Footer Font */
    --navfooterFont: 'Barlow', sans-serif;

    /* TODO rename to Subtitle Font */
    --subhFont: 'Barlow', sans-serif;
    --hmFont: 'Fira Sans', sans-serif;
}

body {
    font-family: var(--mainFont);
    font-weight: 400;
    color: var(--secondaryDark);
    background-color: #FBFBFB;
}

@media screen and (max-width: 991px) {
body {

    overflow-x: hidden;
}
}
body.noscroll {
    overflow:hidden;
}

ul,
ol {
    margin: 0;
    padding: 0;
    list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: 1.2;
    color: var(--secondaryDark);
    font-weight: bold;
    font-family: var(--hmFont);
}
.subh {
    font-family: var(--subhFont);
    text-transform: uppercase;
}

p {
    line-height: 1.3;
    font-size: 16px;
    font-family: var(--mainFont);
    color: var(--secondary);
    margin-bottom: 10px;
    line-height: 24px;
}

a {
    text-decoration: none;
    color: var(--secondaryLight);
}

.row {

      --bs-gutter-x: 0px !important;
}
/* utility css start */
.cs-padding, .m_menu_footer {
    padding-left: calc(24px + 3.75vw) !important;
    padding-right: calc(24px + 3.75vw) !important;
}

.container {
    /* reset max width of container */
    max-width: none;
    /* originally: 1468px */
    /*padding-left: 24px;
    padding-right: 24px;*/
    margin: auto;
}
.container:has(.img_wrap) {
  padding:0px;

  flex-direction: column;
}

.container-inner, .modular-row.form {
    max-width: 1468px;
    margin:auto;
}
.container-inner:has(.col-md-12) {

  padding:0px;
}


.mb-30 {
    margin-bottom: 5rem;
}

.text_lg {
    font-size: 16px;
    line-height: 24px;
}

.ff_fira_sans {
    font-family: var(--hmFont);
}

.text_secondary {
    color: var(--secondary)
}

.text_secondaryLight {
    color: var(--secondaryLight)
}

.box_shadow_black036_16 {
    /*box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);*/
}

.border_radius_sm {
    border-radius: 5px 0px 0px 5px;
  }

.mr-10 {
    margin-right: 10px;
}

/* utility css end */

/* common css start */
.section_wrap{
    padding: 20px 20px;
    display:flex;
    flex-direction: column;
}
:not(.col-md-12).section_wrap:has(.bgcolorwrapper) {
    flex-direction: unset;
}   
.bgcolorwrapper {
    
    display:flex;
    flex-direction: column;
}
.container:has(.img_wrap) {
    flex:1;
    display:flex;
}
.img_wrap {
    flex:1;
    display:flex;
}

.gallery_wrap {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    @media screen and (max-width: 991px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 575px) {
        grid-template-columns: repeat(1, 1fr);
    }
    gap: 30px;
}
.gallery_wrap img {

    height: 220px;
    width: 100%;
    object-fit: cover;
}
/*
.title_wrap {
  margin-top: 30px;
}
*/
.primary-title {
    margin-bottom: 50px;
}

.primary-title h3 {
    font-size: 40px;
    margin-bottom: 20px;
    line-height: 64px;
}

.secondary-title {
    margin-bottom: 45px;
}
.secondary-title h6, .primary-title h6 {
	font-weight: 600;
	color: var(--primary);
	margin-bottom: 3px;
	font-size: 21px;
	line-height: 32px;
  letter-spacing: 1px;
}

.secondary-title h3 {
    font-size: 36px;
    margin-bottom: 1.8rem;
    line-height: 40px;
}


/* common css end */


/* Header Css Start */
header h1,
header h2,
header h3,
header h4,
header h5,
header h6 {
    font-weight: bold;
    font-family: var(--navfooterFont);
}

header, header p, header a, header a.cmn-link.type-2 {
    font-family: var(--navfooterFont);
}

#mobile_bar {
    align-items: center;

    margin-top: -5px;
}
.header-area {
    background-color: var(--tertiary);
    padding: 24px 0px !important;
    position: relative;
    z-index: 55;
}
.header-left {

    justify-content: space-between;
}

.flex-header {

    display: flex;
    justify-content: space-between;
}

.header-right {
    display:flex;
}

header.header-area.fixed {
    position: sticky;
    top: 0;
    opacity: 0.95;
}

.logo {
    max-width: 90px;
}

.main-menu li a {
    color: var(--secondaryDark);
    font-size: 18px;
    /* display: inline-flex; */
    transition: .3s;
    padding: 10px 0;
    align-items: center;
}

.main-menu li a:hover {
    font-weight: normal;
}

.main-menu li.has-children a i {
    margin-left: 8px;
    font-size: 26px;
    line-height: 1;
    vertical-align: middle;
    transform: translateY(2px);
}

.main-menu li.selected>a {
    font-weight: 700;
}

.main-menu li:not(:last-child) {
    margin-right: 55px;
}

.main-menu {
    margin-left: 150px;
}

.main-menu li a svg {
    margin-left: 15px;
}

.main-menu li {
    position: relative;
}

.main-menu li ul.submenu {
    position: absolute;
    top: 105%;
    left: 0;
    background-color: rgba(198, 198, 198, 0.5);
    border-radius: 5px;
    min-width: 225px;
    padding: 10px 0;
    margin-top:10px;
    box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.08);
    opacity: 0;
    visibility: hidden;
    transition: .3s;
}

.main-menu li:hover ul.submenu {
    top: 100%;
    opacity: 1;
    visibility: visible;
}

.main-menu li ul.submenu li a {
    color: var(--secondaryDark);
    display: block;
    padding: 5px 5px;
}
@media screen and (max-width: 991px) {

    .main-menu li ul.submenu li a {
        padding: 5px 10px;
    }

}

.main-menu li ul.submenu li a svg {
    margin-right: 10px;
    margin-left: 0;
    width: 24px;
    height: 24px;
}

.main-menu li ul.submenu li {
    display: block;
    margin: 0 !important;
}

.main-menu li ul.submenu::after {
    content: "";
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent #C6C6C6;
    top: -19px;
    left: 92px;
    position: absolute;
}

.main-menu li.active a {
    font-weight: 700;
}

/* custom button */
.custom-btn,
.custom-btn svg line {
    transition: .3s;
}

.custom-btn {
    border: 2px solid var(--primaryDark);
    background: transparent;
    color: var(--primaryDark);
    padding: 0px 35px 0 20px;
    margin-right:40px;
    height: 45px !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    font-weight: 700;
    font-size: 18px;
    position: relative;
    z-index: 1;
}

.custom-btn:hover {
    border-color: transparent;
    color: #fff;
}

.custom-btn:not([disabled]):after {
    content: "";
    width: 35%;
    height: calc(100% + 4px);
    background: linear-gradient(to right, var(--primary), var(--primaryDark));
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    border-radius: 26px;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    transition: .3s;
}

.custom-btn:hover:after {
    width: calc(100% + 4px);
    border-radius: 5px;
    opacity: 1;
    visibility: visible;
}

.custom-btn:active:after {
    opacity: .72;
}

.custom-btn i {
    position: absolute;
    font-size: 22px;
    top: calc(50%);
    transform: translateY(-50%);
    right: 20px;
    transition: .3s;
}

.custom-btn:not([disabled]):hover i {
    right: 22px;
}

.custom-btn[disabled] {
    background-color: #C6C6C6;
    border-color: #C6C6C6;
    color: #fff;
    cursor: no-drop;
}

.custom-btn.type-2:not([disabled])::after {
    height: 100%;
    width: 100%;
}
/* custom button end */

.box-btn,
.box-btn svg line {
    transition: .3s;
}

.box-btn {
    border: 2px solid var(--primaryDark);
    background: transparent;
    color:  var(--primaryDark);
    padding: 0px 35px 0 20px;
    height: 45px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    font-weight: 600;
    font-size: 18px;
    position: relative;
    z-index: 1;
}

.box-btn:hover {
    border-color: transparent;
    color: #fff;
}

.box-btn:not([disabled]):after {
    content: "";
    width: 35%;
    height: calc(100% + 4px);
    background: linear-gradient(to right, var(--primary),  var(--primaryDark));
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    border-radius: 26px;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    transition: .3s;
}

.box-btn:hover:after {
    width: calc(100% + 4px);
    border-radius: 5px;
    opacity: 1;
    visibility: visible;
}

.box-btn:active:after {
    opacity: .72;
}

.box-btn i {
    position: absolute;
    font-size: 22px;
    top: calc(50% + 2px);
    transform: translateY(-50%);
    right: 20px;
    transition: .3s;
}

.box-btn:not([disabled]):hover i {
    right: 16px;
}

.box-btn[disabled] {
    background-color: #C6C6C6;
    border-color: #C6C6C6;
    color: #fff;
    cursor: no-drop;
}

.box-btn.type-2:not([disabled])::after {
    height: 100%;
    width: 100%;
}

.media-wrap{
    padding: 15px 0;
}

.cmn-link.type-2 {
    font-size: 16px;
    font-family: var(--mainFont);
    color: var(--secondaryDark);
    display: inline-flex;
    align-items: center;
    font-weight: normal;
}

.cmn-link.type-2 i {
    margin-right: 8px;
    font-size: 16px;
}

.header-right a:not(:first-child) {
    margin-left: 40px;
}

.graybar {
    width: 100%;
    height: 80px;
    background-color: var(--tertiary);
}

.cmn-link.type-2.type-3 {
    text-decoration: underline !important;
    color: var(--secondary);
}

.cmn-link.type-2.type-3 svg {
    width: 24px;
    height: 22px;
}

.cmn-link.type-2.type-3 svg path {
    fill: var(--secondary);
}

/* Header Css End */

/* hero-area css start */
.hero-area {
    min-height: 570px;
}

.hero-content {
    max-width: 670px;
    padding: 60px;
}

.hero-video {
    right: 0;
    top: -50px;
}

.hero-video::after {
    content: "";
    width: 80%;
    height: 80%;
    background-color: var(--primaryDark);
    position: absolute;
    left: -50px;
    bottom: -50px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 5px;
}

.hero-video video {
    height: 100%;
    width: 870px;
    position: relative;
    z-index: 1;
    display: block;
    max-height: 600px;
    object-fit: cover;
    border-radius: 5px;
}

.hero-video img{
    height: 100%;
    width: 870px;
    position: relative;
    z-index: 1;
    display: block;
    border-radius: 5px;
}

.hero-content h6 {
    font-size: 24px;
    font-weight: 600;
    color: var(--primary);
    margin-bottom: 10px;
    letter-spacing: 1px;
}

.hero-content h1 {
    font-size: 44px;
    font-weight: 700;
    margin-bottom: 17px;
}

.hero-content p {
    font-size: 16px;
    color: var(--secondary);
    margin-bottom: 10px;
    line-height: 24px;
    margin-bottom: 35px;
}

.box-btn.type-2 {
    background: linear-gradient(to right, var(--primary), var(--primaryDark));
    border-radius: 5px;
    /*box-shadow: 0 6px 12px rgba(0, 0, 0, 0.16);*/
    color: #fff;
    border: 0;
}

.box-btn.type-2:hover {
    background: linear-gradient(to right, var(--primaryDark), var(--primaryLight));

}

.hero-content .box-btn {
    margin-bottom: 28px;
}

.btn_ico{
    width: 20px;
    margin-right: 8px;
    max-height: 20px;
}
/* hero-area css end */

/* brand-area css start */
.brand-area {
    padding: 130px 0;
    overflow:hidden;
}

.brand-item {
    background-color: white;
    box-shadow: 0 6px 30px #f1f1f1;
    border-radius: 5px;
    padding: 20px 15px;
    margin: 9px;
    width: calc(25% - 18px);
    transition: 0.3s;
  }

  .brand-item:hover {
    transform: scale(1.03);
  }

.brand-item img {
    max-height: 70px;
}

.brand-flex-wrapper {
    margin-left: -9px;
    margin-right: -9px;
}

/* brand-area css end */

/* about-area css start */

.about-area {
    background-color: var(--tertiary);
    padding: 105px 0;
}

.about-g-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 75px;
    align-items: center;
}

.sec-img img {
    width: 100%;
    border-radius: 5px;
}

.cmn-content h6 {
    font-size: 24px;
    color: var(--primary);
    font-weight: 600;
    margin-bottom: 6px;
    letter-spacing: 1px;
}

.about-us-area.home_page {
    background-color: #f6f6f6;
}


/* about-area css end */

/* icon-list-area css start */
.icon-list-area {
    padding-top: 10rem;
    padding-bottom: 10rem;
}

.icon-list-area .secondary-title {
    margin-bottom: 65px;
}

.icn-icon {
    max-width: 125px;
    margin-bottom: 24px;
    margin-left: auto;
    margin-right: auto;
}

.cmn-content.icon-list-content h4 {
    font-size: 22px;
    margin-bottom: 10px;
}

.cmn-content.icon-list-content p {
    font-size: 16px;
    color: var(--secondaryLight);
    margin-bottom: 20px;
}

.cmn-content.icon-list-content {
    text-align: center;
}

/* icon-list-area css end */

/* case-post-area css start */

.icon-list-area .secondary-title {
    margin-bottom: 65px;
}

.card_body p {

    color: var(--secondary);
}

.card_title h5 {
    color: var(--primary);
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 8px;
}

.p-name a.u-url{
    color: var(--primary);
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 8px;
}

._card{
    padding: 15px 10px;
}


.card_image img {
    width: 100%;
    border-radius: 5px;
    margin-bottom: 0.5rem;
}

.card_image {
    margin-bottom: 15px;
}

.case-post-area {
    padding: 20px 10px 40px 10px;
}

/* case-post-area css end */

/* cta-area css start */

.cta-area {
    background-color: var(--tertiary);
    padding: 8rem 0rem 4rem 0rem;

}

.cta-area .container-inner {


    padding-right:22px;
    padding-left:22px;
}

.cta-area {
    width:100%;
}

.cta-area .container {

   /* max-width: 1400px; */
    margin:auto;
}
.offer-g-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
    margin-left: 50px;
}

.card_body h5 {
    font-size: 18px;
    font-weight: 700;
    color: var(--secondaryDark);
    margin-bottom: 12px;
}

.card_body p {
    font-size: 16px;
    line-height: 24px;
    color: var(--secondary);
    margin-bottom: 10px;
}

.cmn-link {
    color: var(--primary);
    font-weight: 700;
    font-size: 18px;
    transition: .3s;
}

.cmn-link i {
    margin-left: 5px;
    vertical-align: middle;
    transition: .3s;
    transform: translateY(-1px);
}

.cmn-link:hover {
    color: var(--primaryDark);
}

.cmn-link:hover i {
    margin-left: 8px;
}

.cmn-link svg path {
    fill: var(--primaryDark);
    transition: .3s;
}

.offer-icon {
    margin-bottom: 18px;
}

.cmn-link svg path {
    fill: var(--primaryDark);
    transition: .3s;
}

.cmn-content h3 {
    font-size: 40px;
    margin-bottom: 1.5rem;
    width: 80%;
}

.cmn-content p {
    font-size: 16px;
    color: var(--secondary);
    line-height: 24px;
    margin-bottom: 2rem;
  }

  .txt_module .cmn-content p{
    padding: 0px 0px 0px 0px; 
    margin-bottom: 2rem !important;
  }

.cmn-content.st-content {
    max-width: 565px;
}

/* cta-area css end */

/* Footer Css Start */

footer h1,
footer h2,
footer h3,
footer h4,
footer h5,
footer h6 {
    font-weight: bold;
    font-family: var(--navfooterFont);
}

footer, footer p, footer a {
    font-family: var(--navfooterFont);
}

.footer-top {
    background-color: var(--primaryLight);
}

.ft-widget-first h3 {
}

.ft-g-wrapper {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr;
    padding: 100px 0;
}

.ft-widget h3 {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.3;
    margin-bottom: 4px;
    color: var(--secondaryLight);
}

.ft-nv li a {
    color: var(--secondaryLight);
    font-size: 16px;
    transition: .3s;
    display: inline-block;
}

.ft-nv li {
    line-height: 1.7;
}

.ft-nv li {
    font-size: 16px;
    color: var(--secondaryLight);
}

.ft-widget p {
    font-size: 16px;
    color: var(--secondaryLight);
    line-height: 1.7;
    margin-bottom: 0;
}

.footer-bottom {
    background-color: var(--primaryDark);
    padding: 1rem 0;
}

.footer-bottom p {
    font-size: 16px;
    color: #fff;
}

.ft-links li a {
    display: inline-block;
    color: #fff;
    font-size: 16px;
    transition: .3s;
}

.ft-links li a:hover {
    font-weight: normal;
}

.ft-links li:not(:last-child) {
    position: relative;
    margin-right: 34px;
}

.ft-links li:not(:last-child):before {
    content: "";
    height: 16px;
    width: 2px;
    background-color: #fff;
    position: absolute;
    right: -18px;
    top: 50%;
    transform: translateY(-50%);
}

.ft-widget-first h3 {
    font-size: 28px;
    color: var(--secondaryLight);
    font-weight: 600;
    margin-bottom: 2rem;
}

.social-wrap h5 {
    font-size: 16px;
    color: var(--secondaryLight);
    margin: 0;
}

.social-links li {
    margin-left: 30px;
}

.ft-widget-first p {
    font-size: 16px;
    color: var(--secondaryLight);
    margin-bottom: 0;
}

.social-wrap {
    margin-bottom: 24px;
}

.social-links li a {
    color: #555555;
    font-size: 23px;
    line-height: 1;
    transition: .3s;
}

/* Footer Css End */

/* portfolio-area css start */

.social-links li a.smallsocials {
    font-size:16px;
}

.footerimage {
    margin-bottom: 30px;
    max-width:250px;
    height:auto;
}

.headerimage-blog {
    height: 100%;
    /* max-height: 200px; */
    width: 100%;
    height: 400px;
    object-fit: cover;
    object-position: center center;
    margin-top: 60px;
    margin-bottom: 20px;
}
@media screen and (max-width: 991px) {
    .headerimage-blog {
        height: 300px;
        margin-top: 30px;
        margin-bottom: 0px;
    }
}

.portfolio_wrap{
    padding: 10px 10px;
}

.portfolio-area {
    padding-top: 20px;
}

.product-g-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 60px;
}

.product-img img {
    max-width: 100%;
    width: 100%;
    border-radius: 5px;
    height: 25rem;
    object-fit: cover;
    /*box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);*/
}

.product-item{
    transition: .3s;
}
.product-item:hover {
    transform: scale(1.01);
}

.product-item .cmn-link.type-2 {
    color: var(--primary);
    text-decoration: none !important;
    font-weight: 600;
    font-family: var(--subhFont);
    text-transform: uppercase;
    letter-spacing: 1px;
}

.product-item .cmn-link.type-2 svg {
    margin-left: 0;
}

.product-item .cmn-link.type-2 svg path {
    fill: var(--primary);
}

.product-img {
    margin-bottom: 20px;
}

.product-content h4 {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 10px;
}

.product-item .cmn-link.type-2 svg {
    margin-right: 12px;
}

.bricklayer .bricklayer-column {
    padding-left: 15px;
    padding-right: 15px;
}

/* portfolio-area css end */



.range-g-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 50px;
}

.price-card-g-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 60px;
}

.price-card {
    background-color: #fff;
    border-radius: 10px;
    text-align: center;
    padding: 60px;
    box-shadow: 0 5px 30px rgba(195, 195, 195, 0.16);
}
.price-card.highlight {
    box-shadow: 0 5px 30px rgba(195, 195, 195, 0.16);
    transform: scale(1.15);

}
.price-area {
	padding: 100px 0;
	background-color: var(--tertiary);
	padding-bottom: 130px;
}
.price-area .container-inner {
    padding-right:22px;
    padding-left:22px;
}

.price-area .primary-title {
  width: 63.33%;
}

.price-area p {
    margin-bottom: 6rem;
}
.price > .d-block {
    font-family: var(--subhFont);
}

.range-area {
    padding-bottom: 100px;
    padding-top: 50px;
}

.team-member {
    padding:10px;
}

.team-g-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 60px;
}

.member-avater img {
    width: 100%;
    border-radius: 5px;
}

.price-card .price {
    font-size: 120px;
    color: var(--primaryDark);
    line-height: 1;
    font-weight: 700;
    margin-bottom: 3rem;
  }
  .price-card .price span {
    font-size: 20px;
    color: #8D8D8D;
  }
  .price-card p {
    color: #8D8D8D;
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 2.5rem;
  }

  /*.price-card-g-wrapper .price-card:nth-child(2) {
	transform: scaleY(1.2) scaleX(1.05);
}*/

.price-card-g-wrapper .price-card:nth-child(2) h5 {
  color: var(--primary);
  }

/* product page css end */

/* about-us-area css start */
.about-us-area {
    padding: 70px 0;
    /* padding-bottom: 110px; */
}

/* about-us-area css end */

/* team-area css start */
.team-area {
    padding: 8rem 0;
    background-color: var(--tertiary);

}
.team-area .container-inner {

    padding-right:10px;
    padding-left:10px;
}


.member-img {
    margin-bottom: 24px;
}

.ct-list li a,
.ct-list li {
    color: var(--primary);
    font-size: 18px;
}

.ct-list li i {
    margin-right: 10px;
}

.ct-list li:not(:last-child) {
    margin-bottom: 0.5rem;
}

/* team-area css end */

/* contact-area css start */
.contact-area {
  padding: 2rem 0rem 6rem 0rem;
}

.contact-area .container {
  padding-left: 8px;
}

.contact-area .primary-title {
    margin-bottom: 75px;
}

.address-wrapper {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 0px;
}

.address-wrapper a {
  font-weight: 600;
}

.ol-viewport canvas {
  border-radius: 0px 5px 5px 0px !important;
}

.map-blk iframe {
    width: 100%;
}

.address-blk {
    background-color: #F3F3F3;
    padding: 30px;
}

.address p {
    color: var(--secondaryDark);
    font-size: 18px;
    line-height: 30px;
    margin: 0;
}

.address h6 {
    margin-bottom: 5px;
}

.address {
    margin-top: 24px;
}

/* contact-area css end */

/* quote-area css start */
.carousel-control-next,
.carousel-control-prev {
    color: var(--secondaryDark);
    opacity: 1;
    width: 40px;
    height: 40px;
    top: 50%;
    transform: translateY(-50%);
}

.carousel-control-next i,
.carousel-control-prev i {
    font-size: 28px;
}

.carousel-control-next:hover,
.carousel-control-prev:hover {
    color: #fff;
    background-color: var(--primaryLight);
    border-radius: 5px;
}

.quote-area {
    padding: 8rem 0;
}
.quote-area .container-inner {
    padding-right:22px;
    padding-left:22px;
}

.quote-item {
    max-width: 700px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.quote-item p {
    font-style: italic;
    font-size: 28px;
    color: var(--secondaryLight);
    font-weight: 700;
    line-height: 40px;
}

.quote-inverted {
    max-width: 60px;
    margin-bottom: 40px;
}

.designation {
    font-size: 20px;
    color: var(--primary);
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 1px;
}

.quote-author h6 {
    color: var(--secondaryLight);
    font-size: 28px;
    margin-bottom: 0;
    font-weight: 600;
    letter-spacing: 1px;
}

.quote-author {
    margin-top: 30px;
}

/* quote-area css end */


/* faq-area css start */
.faq-area {
    /* padding-top: 100px;
    padding-bottom: 75px; */
}

.faq-area .secondary-title {
    margin-bottom: 10px;
}

.ac-btn {
    font-size: 26px;
    font-weight: 700;
    background-color: transparent;
    border: 0;
    color: var(--primary);
    position: relative;
    padding: 20px 34px;
    padding-left: 34px;
}

.ac-btn::before {
    content: "\f067";
    font-family: 'Font Awesome 5 Pro';
    font-weight: normal;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: 26px;
    transition: .3s;
}

.ac-btn[aria-expanded="true"]::before {
    content: "\f068";
}

.accordion-item {
    border-radius: 0 !important;
    margin-bottom: 2rem;
}
.container-inner:has(.accordion){
    padding-left:22px;
    padding-right:22px;
}
/* faq-area css end */


/* contact page css end */

/* mobile menu css start */

.mobile_menu {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color:  var(--primaryDark);
    top: 0;
    left: 0;
    z-index: 99;
    right: 0;
    transition: .3s;
    opacity: 0;
    visibility: hidden;
    transform: translateX(100%);
}
.mobile_menu, .mobile_menu a, .mobile_menu p, .mobile_menu li, .mobile_menu li a, .mobile_menu .cmn-link {
    font-family: var(--navfooterFont);
}

.mobile_menu.show {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
}

.close-div {
    text-align: right;
    font-size: 18px;
    color: var(--secondaryDark);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 24px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
}

.close-div button {
    background-color: transparent;
    border: 0;
    padding: 0;
    margin-left: 20px;
    cursor: pointer;
}

.main-menu.m_menu {

    padding-bottom:60px;

}

@media screen and (min-width: 991px) {
    .main-menu.m_menu {

       column-count: 2;
       max-width: 1200px;

    }
    .main-menu.m_menu > li {
        break-inside: avoid;
    }
}

.main-menu.m_menu li ul.submenu {
    position: unset;
    opacity: 1;
    visibility: visible;
    background-color: transparent;
    box-shadow: none;
    padding: 0;
}

.main-menu.m_menu li ul.submenu:after {
    content: none;
}

.main-menu.m_menu li a {
    font-weight: 600;
    font-size: 45px;
}
.main-menu.m_menu > li {
    margin-bottom:30px;
}


.main-menu.m_menu li ul.submenu li a {
    font-weight: 300;
}
@media screen and (max-width: 991px) {
    .main-menu.m_menu li a {
        font-size: 20px;
    }
    .main-menu.m_menu > li > a {
        margin-bottom:10px;
    }

}

.main-menu.m_menu li ul.submenu li a svg {
    width: 20px;
    height: 20px;
    margin-right: 12px;
    transform: translateY(-2px);
}

.m_menu_footer {
	background-color: var(--primaryLight);
	padding: 30px;
	margin-top: auto;
    font-family: var(--navfooterFont);
}
.m_menu_footer, .m_menu_footer a, .m_menu_footer p, .m_menu_footer li, .m_menu_footer h1, m_menu_footer h2, .m_menu_footer h3, .m_menu_footer h4, .m_menu_footer h5, .m_menu_footer h6 {
    font-family: var(--navfooterFont);
}
.mobile_menu-inner {
	overflow-y: scroll;
	height: 100vh;
	padding-top: 60px;
	display: flex;
	flex-direction: column;
}
.m_menu_footer .ft-widget h3, .m_menu_footer .ft-widget p {
	color: #fff;
}
.m_menu_footer .ft-widget:not(:last-child) {
	margin-bottom: 24px;
}
/* mobile menu css end */

.section-map .media-container {
    position: relative;
}

.section-map .media {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    position: relative;
}

.media {
    display: flex;
    align-items: flex-start;
    object-fit: cover;
    width: 100%;
    height: auto;
    border-radius: 5px;
    object-fit: cover;
    object-position: center center;
    flex:1;
}


/* Responsive Query */

@media screen and (max-width: 1600px) {
    .cs-padding {
        padding-left: 24px !important;
        padding-right: 24px !important;
    }

    .hero-video video {
        width: 670px;
    }
    .hero-video img {
        width: 670px;
    }
    .hero-content h1 {
        font-size: 44px;
    }

    .brand-area {
    padding: 12rem 0;
  }

    .about-g-wrapper {
        grid-gap: 30px;
    }
    .hero-content{
        max-width: 670px;
    }


}

@media screen and (max-width: 1439px) {
    .main-menu {
        margin-left: 40px;
    }

    .hero-video video {
        width: 575px;
    }
    .hero-video img {
        width: 575px;
    }
    .hero-content {
        max-width: 600px;
        padding: 60px;
   }

    .hero-content h1 {
        font-size: 42px;
    }
}

@media screen and (max-width: 1199px) {
    .logo {
        max-width: 70px;
    }

    .hero-content {
        max-width: 100%;
        margin-bottom: 100px;
        padding: 30px;
    }

    .hero-video {
        position: relative !important;
        padding: 24px 0px;
    }

    .hero-video video {
        width: 100%;
        margin-top: -60px;
    }
    .hero-video img {
        width: 100%;
        margin-top: -60px;
    }

    .hero-area {
        display: block !important;
        min-height: auto;
        overflow: hidden;
    }

    .hero-video::after {
        left: -24px;
        right: -24px;
        width: calc(100% + 48px);
        height: 100%;
        top: 0;
        bottom: 0;
    }

    .graybar {
        display: none;
    }

    .brand-area {
        padding-top: 50px;
    }

    .about-g-wrapper {
        grid-template-columns: 1fr;
    }

    .header-right a:not(:first-child) {
        margin-left: 24px;
    }
    .product-g-wrapper,
    .price-card-g-wrapper {
        grid-column-gap: 30px;
    }
    .price-card-g-wrapper .price-card:nth-child(2) {
        transform: none;
    }
    .price-area {
        padding: 100px 0;
        padding-bottom: 100px;

    }

    .price-card-g-wrapper {
        grid-template-columns: 1fr;
    }
    .address-wrapper {
        grid-gap: 24px;
        grid-template-columns: 1fr 1.5fr;
    }
    .contact-area {
        padding: 60px 0;
    }
}

@media screen and (max-width: 991px) {
    .header-right a {
        display: none !important;
    }

    .logo {
        max-width: 140px;
    }

    .header-area {
        padding: 20px 0;
    }

    .about-area {
        padding: 70px 0;
    }


    .icon-list-area .mb-30 {
        margin-bottom: 60px;
    }

    .case-post-area,
    .icon-list-area {
        padding-bottom: 0px;
        padding-top: 20px;
    }

    .offer-g-wrapper {
        margin-left: 0;
        margin-top: 55px;
    }

    .cta-area {
        padding: 70px 0;
    }

    .ft-g-wrapper {
        padding: 70px 0;
        grid-template-columns: 1fr 1fr;
        grid-gap: 40px;
    }

    .ft-links li:not(:last-child):before {
        content: none;
    }

    .ft-links li a {
        text-decoration: underline !important;
    }

    .ft-links li {
        margin-top: 15px;
    }

    .footer-bottom {
        background-color: var(--primaryDark);
        padding: 50px 0;
    }
    .team-g-wrapper {
        grid-column-gap: 24px;
        grid-row-gap: 40px;
    }
    .secondary-title h3 {
        font-size: 28px;
        margin-bottom: 15px;
        line-height: 30px;
    }
    .secondary-title h6, .primary-title h6 {
        font-size: 18px;
        line-height: 28px;
    }
    .team-area {
        padding: 80px 0;
    }
    .about-us-area {
        padding: 70px 0;
        padding-bottom: 70px;
    }
    .team-g-wrapper {
        grid-template-columns: 1fr;
    }
    .address-wrapper {
        grid-template-columns: 1fr;
    }

}



@media screen and (max-width: 767px) {
    .secondary-title h3 {
        font-size: 32px;
        margin-bottom: 10px;
        line-height: 37px;
    }

    .secondary-title h6 {
        font-size: 24px;
    }

    .hero-content h1 {
        font-size: 36px;
    }
    .cmn-content h6, .hero-content h6{
        font-size: 24px;
    }
    .hero-content {
        margin-bottom: 95px;
        padding: 15px;
    }
    .hero-video{
        padding: 15px 0px;
    }
    .hero-video video{
        margin-top: -25px;
    }
    .hero-video img{
        margin-top: -25px;
    }
    .brand-area {
        padding-top: 35px;
        padding-bottom: 70px;
    }

    .offer-g-wrapper {
        grid-template-columns: 1fr;
    }

    .ft-g-wrapper {
        grid-template-columns: 1fr;
        grid-gap: 30px;
        padding: 60px 0;
    }

    .cta-area {
        padding: 65px 0;
    }
    .primary-title h3 {
        font-size: 36px;
        margin-bottom: 15px;
        line-height: 45px;
    }
    .product-g-wrapper {
        grid-template-columns: 1fr;
    }
    .portfolio-area {
        padding: 20px 0;
    }
    .price-area {
        padding: 60px 0;
    }
    .quote-item p {
        font-size: 24px;
        line-height: 35px;
    }
    .quote-area {
        padding: 60px 0;
    }
    .ac-btn {
        font-size: 20px;
        padding: 5px 10px 5px 30px;
    }
    .faq-area {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .box-btn{
        height: 50px;
        font-weight: 600;
        font-size: 20px;
    }
    .cmn-content h3{
        font-size: 32px;
    }
}

@media screen and (max-width: 575px) {
    .brand-item {
        margin: 0;
        width: 100%;
    }

    .brand-flex-wrapper .brand-item:not(:last-child) {
        margin-bottom: 45px;
    }
}


/* Start Blog page style */

#blog-hero > .container, #portfolio-hero > .container {
    padding-left:10px;
    padding-right:10px;
}

.container.bloglist {
    padding-left: 0;
    padding-right: 0;
    padding-top:40px;
}

.container-inner:has(.is-post-area) {
    padding-left:10px;
    padding-right:10px;
    padding-bottom: 5rem;
}
.container-inner:has(.js-masonry-list), .brand-flex-wrapper {
    padding-left:20px;
    padding-right:20px;
    padding-bottom: 5rem;
}

.hero {
    background-position: 50% 0;
}
.hero.hero-large {
    min-height: 500px;
}
section.section {
    position: relative;
    padding-right: 1rem;
    padding-left: 1rem;
}
.text-light {
    color: rgba(255,255,255,.8);
}
.hero {
    display: -ms-flexbox;
    display: flex;
    padding-top: 6rem;
    background-position: center;
    background-size: cover;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    flex-direction: column;
}
.overlay-dark-gradient .image-overlay {
    background: linear-gradient(to bottom,rgba(0,0,0,.5),rgba(0,0,0,.2));
}
#blog-hero, #portfolio-hero {
    margin:auto;
}
.image-overlay {
    /*
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    */

    justify-content: normal;
    flex-direction: unset;
}
.hero.text-light h1 {
    color: #fff;
}
/*
.title-h1h2 h1 {
    font-weight: 100;
    line-height: 1.1;
    margin-bottom: 0;
}
*/
.hero h1 {
    font-size: 42px;
    margin-bottom: 1.5rem;
}

.hero p {
  max-width: 63%;
  font-size: 16px;
  color: var(--secondary);
  line-height: 24px;
}


.hero h2 {
    color: rgba(255,255,255,.8);
    font-weight: 600;
    margin-bottom: 50px;
    font-size: 21px;
    color: var(--primary);
    font-weight: 600;
    margin-bottom: 10px;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.hero h6 {
  font-weight: 600;
  color: var(--primary);
  margin-bottom: 3px;
  font-size: 21px;
  line-height: 32px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.label.label-secondary {
    color: var(--primaryLight);
}
.label.label-rounded {
    padding-right: 0.7rem;
    padding-left: 0.7rem;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    border-radius: 5px;
}
.label {
    font-size: 12px;
    text-transform: uppercase;
}
.label {
    line-height: 1.25;
    display: inline-block;
    padding: 0.1rem 0.2rem;
    color: var(--primaryLight);
    border-radius: 0.1rem;
    background: #f0f1f4;
}
#to-start {
    bottom: 3.5rem;
}
.hero #to-start {
    font-size: 2rem;
    position: absolute;
    bottom: 10px;
    display: inline-block;
    cursor: pointer;
}
.pulse {
    transform-origin: 70% 70%;
    animation-name: pulse_animation;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}
/*
.title-h1h2 h1 { font-weight: 100; margin-bottom: 0; line-height: 1.1; }

.title-h1h2 h1 strong, .title-h1h2 h1 bold { font-weight: 400; }

.title-h1h2 h1 + h2 { line-height: 1.1; margin-top: 0; }

.title-h1h2 h1 + h2, .title-center h1 + h2 { margin-bottom: 50px; font-weight: 700; }
*/
.overlay-light, .overlay-dark, .overlay-light-gradient, .overlay-dark-gradient { z-index: 0; }
.overlay-dark-gradient .image-overlay { background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2)); }
.overlay-light-gradient .image-overlay { background: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.2)); }
.overlay-dark .image-overlay { background: rgba(0, 0, 0, 0.4); }
.overlay-light .image-overlay { background: rgba(255, 255, 255, 0.4); }
.hero.hero-fullscreen { min-height: 100vh; }
.hero.hero-large { min-height: 500px; }
.hero.hero-medium { min-height: 400px; }
.hero.hero-small { min-height: 110px; }
.hero.hero-tiny { min-height: 8rem; }


#body-wrapper{
    padding: 40px 10px 60px 10px;
}
/* End Blog page style */


.textimage-text {
    display:flex;
    justify-content: center;
}

.container-inner:has(.swipercontainer) {
    width:100%;
}